import { css } from '@emotion/core'

const noOutline = css({
    outline: 'none'
});

const getInTouch = css({
    position: "fixed",
    right: "20px",
    bottom: "20px",

    button: {
        ':focus': noOutline
    },

    // input: {
    //     height: '100%',
    //     width: '100%',
    //     padding: 8,
    //     paddingRight: 30,
    //     backgroundColor: color.white,
    //     color: color.primary,
    //     borderRadius: 2
    // },
});



export default getInTouch;
