import 'ress'
import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { Global, css } from '@emotion/core'

import { globalStyles } from 'styles'
import favicon from 'images/favicon.ico'
import Footer from 'components/Footer'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import '../../static/styles.css';

interface Props {
  children: JSX.Element | JSX.Element[]
}

const Layout = ({ children }: Props) => (
  <StaticQuery
    query={graphql`
    query {
      ...siteMeta
      ogImage: file(name: { eq: "pe-share" }) {
        image: childImageSharp {
          resize(width: 1200, height: 630, cropFocus: CENTER) {
            width
            height
            src
          }
        }
      }
      ghostSettings {
        title
        description
      }
    }
  `}
    render={data => (
      <div
        css={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
      >
        <Global styles={globalStyles} />
        <Helmet
          defaultTitle={`Project Evident ${data.ghostSettings.title}`}
          titleTemplate={`%s - Project Evident ${data.ghostSettings.title}`}
        >
          <html
            lang="en"
            prefix="og: http://ogp.me/ns# fb: http://ogp.me/ns/fb# website: http://ogp.me/ns/website#"
          />
          <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
          {!!process.env.GATSBY_NOINDEX && (
            <meta name="robots" content="noindex,nofollow" />
          )}
          <meta name="description" content={data.ghostSettings.description} />
          <meta
            property="og:site_name"
            content={`Project Evident ${data.ghostSettings.title}`}
          />
          <meta property="og:url" content={data.site.siteMetadata.baseUrl} />
          <meta
            property="og:title"
            content={`Project Evident ${data.ghostSettings.title}`}
          />
          <meta
            property="og:description"
            content={data.ghostSettings.description}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content={`${data.site.siteMetadata.baseUrl}${
              data.ogImage.image.resize.src
            }`}
          />
          <meta
            property="og:image:width"
            content={data.ogImage.image.resize.width}
          />
          <meta
            property="og:image:height"
            content={data.ogImage.image.resize.height}
          />
        </Helmet>
        <ToastContainer/>
        <div css={{ flex: '1 1 auto' }}>{children}</div>
        <Footer />
      </div>
    )}
  />
)

export default Layout
