import { colors, typography } from 'styles';

import { css } from '@emotion/core'

export const dropdown = css({
    '.dropdown': {
        position: 'relative',
        display: 'inline-block',
    },

    '.dropdown:hover': {
        cursor: 'pointer'
    },

    '.dropdown > a, .dropdown > .dropdown-activator': {
        fontWeight: 500,
        letterSpacing: '0.08em',
        display: 'inline-block',
        color: colors.white,
        textDecoration: 'none',
    },

    '.dropdown > a:before, .dropdown > .dropdown-activator:before': {
        position: 'absolute',
        right: 7,
        top: 12,
        content: '',
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        borderTop: '5px solid black',
    },

    '.dropdown input[type=checkbox]': {
        position: 'absolute',
        display: 'block',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        margin: 0,
        opacity: 0,
    },

    '.dropdown input[type=checkbox]:checked': {
        position: 'fixed',
        zIndex: +0,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },

    '.dropdown ul': {
        position: 'absolute',
        zIndex: 1,
        top: 25,
        border: '1px solid #ccc',
        borderRadius: 3,
        right: 0,
        listStyle: 'none',
        padding: '4px 0px',
        display: 'none',
        backgroundColor: 'white',
        boxShadow: '0 3px 6px rgba(0,0,0,.175)',
    },

    '.dropdown input[type=checkbox]:checked + ul': {
        display: 'block',
    },

    '.dropdown ul li': {
        display: 'block',
        padding: '6px 20px',
        whiteSpace: 'nowrap',
        minWidth: 100,
    },

    '.dropdown ul li:hover': {
        backgroundColor: '#F5F5F5',
        cursor: 'pointer',
    },

    '.dropdown ul li a': {
        textDecoration: 'none',
        display: 'block',
        color: 'black'
    },

    '.dropdown .divider': {
        height: 1,
        margin: '9px 0',
        overflow: 'hidden',
        backgroundColor: '#e5e5e5',
        fontSize: 1,
        padding: 0,
    }
});
