export const categoriesInHeader = [
    'Data',
    'Diversity Equity Inclusion',
    'Evaluation',
    'Evidence Based Policy',
    'Improving Programs',
    'Reporting',
    'Strategic Evidence Planning',
    'Technology',
    'Theory of Change'
];

export const salesForceOid = '00D1N000002c6Lh';
