import { css } from '@emotion/core'
import * as colors from './constants/colors'

const linkUnderline = css({
  color: colors.link,
  textDecoration: 'none',
  backgroundRepeat: 'repeat-x',
  backgroundImage: `linear-gradient(to right,${colors.primary} 100%,${
    colors.primary
  } 0)`,
  backgroundSize: '1px 1px',
  backgroundPosition: '0 calc(1em + 1px)',
})

export default linkUnderline
