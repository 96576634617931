import React from 'react'
import HeaderLogo from './HeaderLogo'
import HeaderNav from './HeaderNav'
import { centeredContent, colors } from 'styles'

const Header = ({ styles }) =>  (
  <header css={{
    backgroundColor: colors.primary
  }}>
    <div
      css={[
        centeredContent(1080),
        {
          padding: '1.6rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        },
        {
          ...styles
        }
      ]}
    >
      <HeaderLogo />
      <HeaderNav />
    </div>

  </header>
)

export default Header
