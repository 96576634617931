import React from 'react';
import styled from '@emotion/styled';
import { ClassNames } from '@emotion/core';
import { StaticQuery, graphql } from 'gatsby';
import { typography, colors, dropdown } from 'styles';
import Link from './Link';
import { categoriesInHeader } from 'project-evident-config';
import magGlassIcon from 'images/mag-glass.png';

const MAX_TAGS_TO_SHOW = 15;

interface NavItem {
  url: string;
  label: string;
}

const NavList = styled.ul({
  listStyle: 'none',
  display: 'flex',
  flexWrap: 'wrap',
  marginLeft: '-1.25rem',
})

const NavListItem = styled.li({
  margin: '0.5rem 1.25rem',
})

const NavLink = styled(Link)(
  {
    ...typography.body,
    textDecoration: 'none',
    fontWeight: 500,
    letterSpacing: '0.08em',
    color: colors.white
  }
)

interface Tag {
  node: {
    ghostId: string;
    slug: string;
    name: string;
    posts: Array<any>;
  }
}

const HeaderNav = () => {
  return <StaticQuery
    query={graphql`
      query {
        ghostSettings {
          navigation {
            label
            url
          }
        }

        allGhostTag(
          filter: {
            visibility: { eq: "public" } # only public tags...
            posts: { elemMatch: { id: { ne: null } } } # ...with posts
          }
        ) {
          edges {
            node {
              ghostId
              slug
              name
              posts {
                id
              }
            }
          }
        }

      }
    `}
    render={data => {
      function comparer (a: Tag, b: Tag) {
        const postsInA = a.node.posts ? a.node.posts.length : 0;
        const postsInB = b.node.posts ? b.node.posts.length : 0;

        if (postsInA > postsInB) return -1;
        else if (postsInA < postsInB) return 1;

        return 0;
      }

      const sortedTags = data.allGhostTag.edges
        .filter((tag: Tag) => categoriesInHeader.indexOf(tag.node.name) !== -1)
        .sort(comparer)
        .map((tag: Tag) => tag.node)
        .slice(0, MAX_TAGS_TO_SHOW);

      return (
        <nav css={{ margin: '1rem 0' }}>
          <NavList>
            {data.ghostSettings.navigation.map((navItem: NavItem) => (
              <NavListItem key={navItem.url}>
                <ClassNames>
                  {({ css }) => (
                    <NavLink
                      to={navItem.url}
                    >
                      {navItem.label}
                    </NavLink>
                  )}
                </ClassNames>
              </NavListItem>
            ))}

            <NavListItem key='categories' css={dropdown.dropdown}>
              <ClassNames>
                {({ css }) => (
                  <div className="dropdown">
                    <div className="dropdown-activator">Topics ▾</div>
                    <label>
                      <input type="checkbox" />
                      <ul>
                        {
                          sortedTags.map(({ name, slug, posts }) => (
                            <li key={slug}>
                              <NavLink to={`/c/${slug}`}>{`${name} ${posts && posts.length ? `(${posts.length})` : ''}`}</NavLink>
                            </li>
                          ))
                        }
                      </ul>
                    </label>
                  </div>
                )}
              </ClassNames>
            </NavListItem>


            <NavListItem key='/search'>
              <ClassNames>
                {({ css }) => (
                  <NavLink
                    to={'/search'}
                  >
                    <img
                      css={{
                        height: '30px',
                        width: '30px',
                        marginTop: '-5px'
                      }}
                      src={magGlassIcon}
                    />

                  </NavLink>
                )}
              </ClassNames>
            </NavListItem>
          </NavList>
        </nav>
      );
    }}
  />
}

export default HeaderNav
