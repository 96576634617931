import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { typography, colors } from 'styles'

const PELogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="45"
    viewBox="0 0 99.97 139.95"
  >
    <g fill="currentColor">
      <path d="M19.99 19.99h59.98V0H0v139.95h79.97v-19.99H19.99V19.99z" />
      <path d="M39.99 39.99v19.99h39.98v19.99H39.99v20h59.98V39.99H39.99z" />
    </g>
  </svg>
)

const HeaderLogo = () => (
  <StaticQuery
    query={graphql`
      query {
        ghostSettings {
          title
        }
      }
    `}
    render={data => (
      <Link
        to="/"
        css={{
          ...typography.headline4,
          color: colors.white,
          textDecoration: 'none',
          display: 'inline-flex',
          alignItems: 'center',
          verticalAlign: 'middle',
        }}
      >
        <PELogo />
        <span css={{ marginLeft: '1rem' }}>{data.ghostSettings.title}</span>
      </Link>
    )}
  />
)

export default HeaderLogo
