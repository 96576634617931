import * as colors from './constants/colors';

export const display = {
  fontSize: '3.157rem',
  lineHeight: 1.15,
  fontWeight: 700,
  letterSpacing: '-0.025em',
}

export const headline1 = {
  margin: '0 0 25px',
  fontFamily: 'Roboto',
  fontSize: '2rem',
  order: 2
}

export const headline2 = {
  fontSize: '1.777rem',
  lineHeight: 1.25,
  fontWeight: 700,
  letterSpacing: '-0.01em',
}

export const headline3 = {
  fontSize: '1.333rem',
  lineHeight: 1.25,
  fontWeight: 700,
  letterSpacing: '-0.01em',
}

export const headline4 = {
  fontSize: '1rem',
  lineHeight: 1.5,
  fontWeight: 700,
}

export const body = {
  fontFamily: 'Roboto,Arial,Helvetica,sans-serif',
  fontWeight: 400,
  fontStyle: 'normal',
  fontSize: '16px',
  letterSpacing: '.01em',
  lineHeight: '1.4em',
  textTransform: 'none',
  color: colors.primary,

  // fontSize: '1rem',
  // lineHeight: 1.5,
  // fontWeight: 400,
}

export const caption = {
  fontSize: '0.844rem',
  lineHeight: 1.33,
  fontWeight: 400,
  letterSpacing: '0.01em',
}

export const micro = {
  fontSize: '0.633rem',
  lineHeight: 14 / 10,
  fontWeight: 700,
  letterSpacing: '0.02em',
  textTransform: 'uppercase',
}
