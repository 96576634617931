import { css } from '@emotion/core'
import * as typography from './typography';
import * as color from './constants/colors';
import { colors } from 'styles';

const search = css({
    '.ais-InstantSearch__root': {
        display: 'flex',
    },

    '.sidebar': {
        width: 300,
        minWidth: 300,
        padding: 8
    },

    '.search-panel__results': {
        flexGrow: 1,
        padding: 8
    },

    ul: {
        listStyle: 'none'
    },

    'ul > li.ais-Hits-item': {
        marginBottom: '0.6rem'
    },

    'form.ais-SearchBox-form': {
        position: 'relative',
        marginBottom: '2rem'
    },

    'form.ais-SearchBox-form > input.ais-SearchBox-input': {
        background: 'none',
        color: '#c6c6c6',
        fontSize: '18px',
        padding: '10px 10px 10px 5px',
        display: 'block',
        width: '100%',
        border: 'none',
        borderRadius: 0,
        borderBottom: '1px solid #c6c6c6',
        paddingRight: '40px'
    },

    'form.ais-SearchBox-form > button.ais-SearchBox-submit': {
        position: 'absolute',
        top: '6px',
        right: '0px',
        padding: '5px',
        borderRadius: '50%',
        backgroundColor: 'rgba(0,0,0,0.2)',
        width: '35px',
        height: '35px',
    },

    'form.ais-SearchBox-form > button.ais-SearchBox-submit::-moz-focus-inner': {
        border: 0
    },

    'form.ais-SearchBox-form > button.ais-SearchBox-reset': {
        display: 'none'
    },

    '.search-filters': {
        fontSize: '0.8rem'
    },

    '.search-filters h3': {
        marginBottom: 8,
        display: 'flex'
    },

    '.search-filters section': {
        paddingLeft: '0.3rem',
        paddingBottom: 8,
        paddingTop: 8,
        borderTop: '2px solid rgba(0,0,0,0.3)',
    },

    '.search-filters section:last-of-type': {
        borderBottom: '2px solid rgba(0,0,0,0.3)'
    },

    '.search-filters section .input-group': {
        display: 'flex',
        lineHeight: '1.4rem'
    },

    '.search-filters section input': {
        marginRight: 4
    }

});

const hit = css({
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '2px solid rgba(0,0,0,0.2)',
    marginBottom: 24,
    paddingBottom: 16,

    '.text': {
        flexGrow: 1
    },

    '.image': {

    },

    a: {
        display: 'block',
        textDecoration: 'none',
        color: 'black',
        padding: '2px 4px'
    },

    'a:hover': {
        opacity: 0.5
    },

    h3: {
        ...typography.headline3,
        fontWeight: 400,
        marginBottom: 16
    },

    img: {
        maxWidth: 400
    },

    '.excerpt': {
        fontSize: '.9rem',
        marginLeft: '0.3rem',
        marginBottom: '.5rem'
    },

    '.excerpt > .highlighted': {
        color: 'white',
        backgroundColor: color.primary,
        borderRadius: '4px',
        padding: '0px 4px'
    },

    '.author-category': {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '0.3rem',
        color: colors.primary,
        marginBottom: 8,
        fontSize: '0.86rem',
        marginRight: 16
    },

    '.author-category a': {
        display: 'inline',
        padding: 0,
        fontWeight: 600,
        textDecoration: 'underline',
        color: colors.link
    },

    '.author-category > .category': {
        flexGrow: 1,
        textAlign: 'right',
        textTransform: 'uppercase',
        fontSize: '0.8rem',
        marginTop: 1,
        color: colors.link
    }
});

const pagination = css({
    '.ais-Pagination-list': {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center'
    },

    '.ais-Pagination-list li span': {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center'
    },

    '.ais-Pagination-list li a': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center'
    },

    '.ais-Pagination-list li': {
        backgroundColor: color.primary
    },

    '.ais-Pagination-list a': {
      fontWeight: 300,
      paddingTop: '1px',
      textDecoration: 'none',
      border: '1px solid rgba(0,0,0,.25)',
      borderLeftWidth: 0,
      minWidth: '44px',
      minHeight: '44px',
      color: color.white,
      boxShadow: 'inset 0px 1px 0px 0px rgba(255,255,255,.35)',
    },

    '.ais-Pagination-list span': {
        fontWeight: 300,
        paddingTop: '1px',
        textDecoration: 'none',
        border: '1px solid rgba(0,0,0,.25)',
        borderLeftWidth: 0,
        minWidth: '44px',
        minHeight: '44px',
        color: color.white,
        boxShadow: 'inset 0px 1px 0px 0px rgba(255,255,255,.35)',
      },

    '.ais-Pagination-list .ais-Pagination-item--selected': {
        cursor: 'not-allowed'
    },

    '.ais-Pagination-list li:not([class*="ais-Pagination-item--selected"]) a:hover': {
      backgroundColor: 'rgba(255,255,255,.2)',
      borderTopColor: 'rgba(0,0,0,.35)',
      borderBottomColor: 'rgba(0,0,0,.5)',
    },

    '.ais-Pagination-list li:not([class*="current"]) a:focus, .ais-Pagination-list li:not([class*="current"]) a:active': {
      boxShadow: '0px 0px 10px 1px rgba(0,0,0,.25)',
      borderLeftWidth: '1px',
    },

    '.ais-Pagination-list li:first-of-type a': {
      borderLeftWidth: '1px',
    }
});

export {
    hit,
    search,
    pagination
}
