const globalStyles = {
  html: {
    fontSize: '100%',
  },
  body: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: 'rgba(0,0,0,0.87)',
    lineHeight: 1.45,
  },
}

export default globalStyles
