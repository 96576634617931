import { css } from '@emotion/core'
import * as typography from './typography'
import * as colors from './constants/colors'

const smallBreakpoint = '@media (min-width: 760px)'

const markdown = css({
  h1: {
    ...typography.headline1,
    marginTop: '3.8rem',
    marginBottom: '0.8rem',
  },
  h2: {
    ...typography.headline2,
    marginTop: '2.4rem',
    marginBottom: '0.8rem',
  },
  'h3, h4, h5, h6': {
    ...typography.headline3,
    marginTop: '1.6rem',
    marginBottom: '0.8rem',
  },
  'p, ul, ol, dl, blockquote': {
    fontSize: '1rem',
    lineHeight: '1.4rem',
    marginBottom: '1.6rem',
  },
  li: {
    marginLeft: '2rem',
    marginBottom: '1rem',
  },
  'li > ul, li > ol': {
    marginTop: '0.8rem',
  },
  dl: {
    marginBottom: '1.6rem',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    [smallBreakpoint]: {
      flexDirection: 'row',
    },
  },
  'dd:not(:last-of-type)': {
    marginBottom: '1.6rem',
  },
  'dt:not(:last-of-type)': {
    marginBottom: 0,
    [smallBreakpoint]: {
      marginBottom: '1.6rem',
    },
  },
  dt: {
    fontWeight: 700,
    [smallBreakpoint]: {
      flex: '0 0 160px',
      textAlign: 'right',
    },
  },
  dd: {
    [smallBreakpoint]: {
      width: 'calc(100% - 160px)',
      paddingLeft: '1.6rem',
    },
  },
  blockquote: {
    borderLeft: `3px solid ${colors.primary}`,
    paddingLeft: 'calc(1.6rem - 3px)',
    marginLeft: '-1.6rem',
    paddingBottom: 2,
    fontStyle: 'italic',
    marginBottom: '1.6rem',
    [smallBreakpoint]: {
      paddingLeft: '1.6rem',
      marginLeft: 'calc(-1.6rem - 3px)',
    },
  },
  'blockquote > p': {
    marginBottom: 0,
  },
  'pre, code': {
    fontFamily:
      '"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace',
  },
  pre: {
    whiteSpace: 'pre-wrap',
    padding: '1.6rem',
    background: 'rgba(0,0,0,0.05)',
    marginBottom: '1.6rem',
    tabSize: 4,
    borderRadius: 4,
    overflow: 'auto',
  },
  code: {
    padding: '0.2em 0.4em',
    margin: 0,
    fontSize: '85%',
    backgroundColor: 'rgba(27,31,35,0.05)',
    borderRadius: 3,
  },
  'pre > code': {
    padding: 0,
    margin: 0,
    fontSize: '100%',
    wordBreak: 'normal',
    whiteSpace: 'pre',
    background: 'transparent',
    border: 0,
  },
  hr: {
    height: 0,
    margin: '1.6rem 0',
    overflow: 'hidden',
    background: 'transparent',
    border: 0,
    borderBottom: '1px solid #dfe2e5',
  },
  figure: {
    marginBottom: '3.2rem',
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
    margin: '0 auto',
    display: 'block',
  },
  'figure.kg-width-full > img, figure.kg-width-wide > img': {
    width: '100vw',
    position: 'relative',
  },
  'figure.kg-width-full > img': {
    maxWidth: 'none',
    margin: '0 -50vw',
    left: '50%',
    right: '50%',
  },
  'figure.kg-width-wide > img': {
    maxWidth: 1000,
    margin: '0 calc(50% - 50vw)',
    transform: 'translateX(calc(50vw - 50%))',
  },
  figcaption: {
    textAlign: 'center',
    marginTop: '0.8rem',
    color: '#54666d',
    ...typography.caption,
  },
  a: {
    color: colors.primary,
    textDecoration: 'none',
    backgroundRepeat: 'repeat-x',
    backgroundImage: `linear-gradient(to right,${colors.primary} 100%,${
      colors.primary
    } 0)`,
    backgroundSize: '1px 1px',
    backgroundPosition: '0 calc(1em + 1px)',
  },
  table: {
    display: 'block',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    marginBottom: '1.6rem',
    width: '100%',
    minWidth: '100%',
    overflow: 'auto',
    fontSize: '1.1rem',
  },
  'td, th': {
    padding: '0.533rem 0.8rem ',
    borderBottom: '1px solid #e5eff5',
    verticalAlign: 'top',
    textAlign: 'left',
  },
  iframe: {
    display: 'block',
    margin: '0 auto 1.6rem !important',
  },
  '.kg-gallery-container': {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1000,
    width: '100vw',
    transform: 'translateX(calc(50vw - 50%))',
    margin: '0 calc(50% - 50vw)',
  },
  '.kg-gallery-row': {
    display: 'flex',
    justifyContent: 'center',
  },
  '.kg-gallery-row:not(:last-child)': {
    marginBottom: '0.75rem',
  },
  '.kg-gallery-image:not(:last-child)': {
    marginRight: '0.75rem',
  },
  '.kg-gallery-image > img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
})

export default markdown
