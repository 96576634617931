import React from 'react'
import { Omit } from 'types/types'
import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby'

interface NavLinkProps<TState> extends Omit<GatsbyLinkProps<TState>, 'ref'> {}

const Link = React.forwardRef(
  (
    { to, children, ...props }: NavLinkProps<{}>,
    ref: React.Ref<HTMLAnchorElement>
  ) => {
    const isAbsolute = /^[a-z][a-z0-9+.-]*:/.test(to)
    const { activeClassName, activeStyle, ...anchorProps } = props

    return isAbsolute ? (
      <a href={to} ref={ref} {...anchorProps}>
        {children}
      </a>
    ) : (
      <GatsbyLink
        to={to}
        innerRef={ref as Function}
        activeClassName={activeClassName}
        activeStyle={activeStyle}
        {...anchorProps}
      >
        {children}
      </GatsbyLink>
    )
  }
)

export default Link
