import { GhostTag } from 'types/ghost'
import { GqlQueryResult } from 'types/graphql'
import postHasPrimaryTag from './postHasPrimaryTag'

const getPrimaryTags = (tags: GqlQueryResult<GhostTag>) =>
  tags.edges.filter(
    ({ node: tag }) =>
      tag.posts && !!tag.posts.find(post => postHasPrimaryTag(post, tag))
  )

export default getPrimaryTags
