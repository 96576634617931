import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import { centeredContent, colors, typography } from 'styles';
import getPrimaryTags from 'lib/getPrimaryTags';
import GetInTouch from 'components/GetInTouch';


const smallBreakpoint = '@media (min-width: 450px)'

const CategoryList = styled.ul({
  listStyle: 'none',
  columnCount: 2,
  marginBottom: '1.6rem',
  '@media (min-width: 550px)': {
    columnCount: 3,
  },
})

const ListItem = styled.li({
  marginBottom: '0.4rem',
  breakInside: 'avoid',
})

const ListLink = styled(Link)({
  ...typography.body,
  fontWeight: 700,
  color: colors.white,
  textDecoration: 'none',
  transition: 'color 100ms linear',
  ':hover': {
    color: 'rgba(255,255,255,0.6)',
  },
})

const LegalList = styled.ul({
  listStyle: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [smallBreakpoint]: {
    flexDirection: 'row',
    alignItems: 'initial',
  },
})

const LegalListItem = styled.li({
  ':not(:last-child)': {
    marginBottom: '0.5rem',
    [smallBreakpoint]: {
      marginBottom: 0,
      marginRight: '1rem',
    },
  },
})

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        allGhostTag(filter: { visibility: { eq: "public" } }) {
          edges {
            node {
              id
              name
              fields {
                permalink
              }
              posts {
                primary_tag {
                  id
                }
              }
            }
          }
        }
        allGhostPage(
          filter: { tags: { elemMatch: { name: { eq: "#boilerplate" } } } }
        ) {
          edges {
            node {
              id
              title
              slug
            }
          }
        }
        legalPages: allGhostPage(
          filter: { tags: { elemMatch: { name: { eq: "#legal" } } } }
        ) {
          edges {
            node {
              id
              title
              slug
            }
          }
        }
      }
    `}
    render={data => (
      <footer
        css={{
        backgroundColor: colors.footer,
          color: colors.white,
        }}
      >
        <GetInTouch></GetInTouch>

        <div
          css={[
            centeredContent(1080),
            { paddingTop: '3.2rem', paddingBottom: '2rem' },
          ]}
        >
          <CategoryList>
            {getPrimaryTags(data.allGhostTag).map(({ node: tag }) => (
              <ListItem key={tag.id}>
                <ListLink to={tag.fields.permalink}>{tag.name}</ListLink>
              </ListItem>
            ))}
          </CategoryList>
          <CategoryList>
            {data.allGhostPage && data.allGhostPage.edges.map(({ node: page }) => (
              <ListItem key={page.id}>
                <ListLink css={{ fontWeight: 400 }} to={page.slug}>
                  {page.title}
                </ListLink>
              </ListItem>
            ))}
          </CategoryList>
          <div
            css={{
              display: 'flex',
              marginTop: '3.2rem',
              flexDirection: 'column',
              alignItems: 'center',
              [smallBreakpoint]: {
                flexDirection: 'row',
                alignItems: 'baseline',
              },
            }}
          >
            <p
              css={{
                ...typography.caption,
                marginBottom: '0.5rem',
                [smallBreakpoint]: {
                  marginBottom: 0,
                  marginRight: '1rem',
                },
              }}
            >
              © {new Date().getFullYear()} Project Evident
            </p>
            <LegalList>
              {data.legalPages && data.legalPages.edges.map(({ node: page }) => (
                <LegalListItem key={page.id}>
                  <Link
                    to={page.slug}
                    css={{ color: colors.white, ...typography.caption }}
                  >
                    {page.title}
                  </Link>
                </LegalListItem>
              ))}
            </LegalList>
          </div>
        </div>
      </footer>
    )}
  />
)

export default Footer
