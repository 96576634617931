import React, { Component } from 'react';
import Modal from 'react-modal';

import axios from 'axios';
import { colors, getInTouch } from 'styles';
import { toast } from 'react-toastify';
import styled from "@emotion/styled";
import { salesForceOid } from "project-evident-config";

import letterEnvelop from 'images/letter-envelop.png';
import { css } from "@emotion/core";

const LoaderIndicator = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 44 44" stroke="#ff664b">
        <g fill="none" fillRule="evenodd" strokeWidth="3">
            <circle cx="22" cy="22" r="19.3341">
                <animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite" />
                <animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite" />
            </circle>
            <circle cx="22" cy="22" r="10.5101">
                <animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite" />
                <animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite" />
            </circle>
        </g>
    </svg>
)

interface State {
    loading: boolean,
    popup: boolean;
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    description: string;
}

interface Props {
}

const ModalHeader = styled.h2({
    marginBottom: '20px'
});

const ModalCloseButton = styled.button({
    position: "absolute",
    top: "4px",
    right: "10px"
});

const ModalBody = styled.div({
});

const FlexRow = styled.div(`
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;

    @media (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 0;
    }
`);

const FlexColumn = styled.div(`
    flex-grow: 1;
    flex-basis: 0;
`);

const Label = styled.label({
    fontSize: '13px'
});

const TextInput = styled.input(`
    border: 1px solid;
    border-color: rgba(0,0,0,.2);
    padding: 4px;
    border-radius: 4px;
    margin-right: 10px;
    flex-grow: 1;
    font-size: 14px;

    @media (max-width: 768px) {
        margin-bottom: 8px;
    }
`);

const TextArea = styled.textarea({
    border: '1px solid',
    borderColor: 'rgba(0,0,0,.2)',
    padding: '4px',
    borderRadius: '4px',
    marginRight: '10px',
    flexGrow: 1,
    fontSize: '14px',
    height: '100px'
});

const Button = styled.button(`
    padding: 8px;
    border-radius: 2px;
    background-color: ${colors.link};
    color: ${colors.white};

    @media (max-width: 768px) {
        margin-top: 8px;
    }
`);

const FormLoader = styled.div({
    zIndex: 999,
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

export default class GetInTouch extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            popup: false,
            firstName: '',
            lastName: '',
            email: '',
            company: '',
            description: ''
        }
    }

    componentDidMount () {
        Modal.setAppElement('#get-in-touch');
    }

    handleInputChange (value: any, field: keyof State) {
        this.setState({ [field]: value });
    };

    handleShowToggle (show: boolean) {
        this.setState({ popup: show });
    }

    async handleSubmit (event) {
        event.preventDefault();

        if (!this.state.firstName || !this.state.lastName || !this.state.description) {
            toast('First name, last name and description fields are required!', { type: 'warning' });
            return;
        };

        this.setState({ loading: true });

        var formData = new FormData();
        formData.set('oid', salesForceOid);
        formData.set('retURL', 'http://example.com');
        formData.set('first_name', this.state.firstName);
        formData.set('last_name', this.state.lastName);
        formData.set('email', this.state.email);
        formData.set('company', this.state.company);
        formData.set('description', this.state.description);

        const url = 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8';
        let success = false;

        try {
            const response = await axios({
                url,
                method: 'POST',
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' }
            })

            console.log("Response OK:", response);
            toast('Your message was sent!', { type: 'success' });
            success = true;
        } catch (error) {
            if (error.message === 'Network Error') {
                // Known issue due CORS as we posting from another URL but POST request still sent ok
                toast('Your message was sent!', { type: 'success' });
                success = true;
                return;
            }

            toast('Unable to send message!', { type: 'error' });
        } finally {
            let state: any = { loading: false }

            if (success) {
                state = {
                    ...state,
                    popup: false,
                    firstName: '',
                    lastName: '',
                    email: '',
                    company: '',
                    description: ''
                };
            }

            this.setState(state);
        }
    }

    render () {
        const customStyles = {
            content: {
                // top: '50%',
                // left: '50%',
                // right: 'auto',
                // bottom: 'auto',
                // marginRight: '-35%',
                // transform: 'translate(-50%, -50%)',
            }
        };

        const retUrl = typeof location !== 'undefined' ? location.href : 'https://toolkit.projectevident.org/';

        return (
            <div id="get-in-touch" css={getInTouch}>
                {this.state.loading &&
                    <FormLoader>
                        <LoaderIndicator />
                    </FormLoader>
                }

                <button onClick={() => this.handleShowToggle(true)} >
                    <img src={letterEnvelop} alt="Get in touch" style={{ width: "50px" }} />
                </button>

                <Modal
                    isOpen={this.state.popup}
                    onRequestClose={() => this.handleShowToggle(false)}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <ModalHeader>
                        Get in touch
                        <ModalCloseButton onClick={() => this.handleShowToggle(false)}>×</ModalCloseButton>
                    </ModalHeader>

                    <ModalBody>
                        <form method="POST" action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8">
                            <input type="hidden" name="oid" value={salesForceOid} />
                            <input type="hidden" name="retURL" value={retUrl} />

                            <FlexRow>
                                <FlexColumn css={{ paddingRight: "20px" }}>
                                    <h3>Still have questions? Project Evident is here to help.</h3>

                                    <p css={{ marginTop: "20px" }}>
                                        If you are interested in incorporating any of the practices in this toolkit into
                                        your own organization but don't know where to start, we can help.
                                    </p>
                                    <p css={{ marginTop: "20px" }}>
                                        Please complete the form and our team will be in touch with next steps.
                                    </p>
                                </FlexColumn>

                                <FlexColumn css={{ paddingLeft: "20px" }}>
                                    <FlexRow>
                                        <FlexColumn>
                                            <Label>Name *</Label>

                                            <FlexRow>
                                                <TextInput
                                                    type="text"
                                                    name="first_name"
                                                    required
                                                    placeholder="First name"
                                                    onChange={event => this.handleInputChange(event.target.value, 'firstName')}
                                                ></TextInput>
                                                <TextInput
                                                    type="text"
                                                    name="last_name"
                                                    required
                                                    placeholder="Last name"
                                                    onChange={event => this.handleInputChange(event.target.value, 'lastName')}
                                                ></TextInput>
                                            </FlexRow>
                                        </FlexColumn>
                                    </FlexRow>

                                    <FlexRow>
                                        <FlexColumn>
                                            <Label>Email</Label>

                                            <FlexRow>
                                                <TextInput
                                                    type="text"
                                                    name="email"
                                                    required
                                                    onChange={event => this.handleInputChange(event.target.value, 'email')}
                                                ></TextInput>
                                            </FlexRow>
                                        </FlexColumn>
                                    </FlexRow>

                                    <FlexRow>
                                        <FlexColumn>
                                            <Label>Organization</Label>

                                            <FlexRow>
                                                <TextInput
                                                    type="text"
                                                    name="company"
                                                    onChange={event => this.handleInputChange(event.target.value, 'company')}
                                                ></TextInput>
                                            </FlexRow>
                                        </FlexColumn>
                                    </FlexRow>

                                    <FlexRow>
                                        <FlexColumn>
                                            <Label>Message *</Label>

                                            <FlexRow>
                                                <TextArea
                                                    name="description"
                                                    required
                                                    onChange={event => this.handleInputChange(event.target.value, 'description')}
                                                ></TextArea>
                                            </FlexRow>
                                        </FlexColumn>
                                    </FlexRow>

                                    <FlexRow>
                                        <Button type="submit" name="submit">Send Message</Button>
                                    </FlexRow>
                                </FlexColumn>

                            </FlexRow>
                        </form>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
