import { css } from '@emotion/core'

const centeredContent = (maxWidth: string | number = 740) =>
  css({
    margin: '0 auto',
    padding: '0 1.6rem',
    maxWidth,
  })

export default centeredContent
